import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import {
  CloseSquare,
  HambergerMenu,
  ArrowDown2,
  ArrowUp2,
} from 'iconsax-react';
import { DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RoutePaths } from '../../routes/route-paths';
import SectionLayout from '../../layouts/SectionLayout';
import { Link as ScrollLink } from 'react-scroll';
import Modal from '../shared/modal';

const Header: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleGetStarted = () => {
    navigate(RoutePaths.GETSTARTED);
  };

  const handleLogin = () => {
    navigate(RoutePaths.LOGIN);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleServicesVisibleChange = (flag: boolean) => {
    setServicesOpen(flag);
  };

  const servicesMenu: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          to={RoutePaths.SCHOOLFEES_GETSTARTED}
          className="text-BrandGray hover:text-BrandPrimary"
        >
          School Fees
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link
          to={RoutePaths.RENT_GETSTARTED}
          className="text-BrandGray hover:text-BrandPrimary"
        >
          House Rent
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <Link
          to={RoutePaths.TRANSPORT_GETSTARTED}
          className="text-BrandGray hover:text-BrandPrimary"
        >
          Transport Credit
        </Link>
      ),
    },
  ];

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to="/" className="text-BrandGray hover:text-BrandPrimary">
          Home
        </Link>
      ),
    },
    {
      key: '4',
      label: (
        <ScrollLink
          to="footer"
          spy={true}
          smooth={true}
          duration={2000}
          className="text-BrandGray hover:text-BrandPrimary text-sm lg:text-base cursor-pointer"
          activeClass="some-active-class"
        >
          Contact Us
        </ScrollLink>
      ),
    },
    {
      key: '2',
      label: 'Services',
      children: servicesMenu,
    },
    {
      key: '5',
      label: (
        <Button
          onClick={handleLogin}
          className="bg-white text-BrandPrimary border border-BrandPrimary px-10 lg:px-16 py-[6px] lg:py-5 text-sm lg:text-base font-normal w-full text-left"
        >
          Login
        </Button>
      ),
    },
    {
      key: '6',
      label: (
        <Button
          onClick={handleGetStarted}
          className="bg-BrandPrimary text-white px-4 lg:px-10 py-1 lg:py-5 text-sm lg:text-base font-normal w-full text-left "
        >
          Get Started
        </Button>
      ),
    },
  ];

  return (
    <nav className="bg-white">
      <SectionLayout>
        <div className="flex justify-between items-center py-4">
          <img src="/Assets/Jompstart Logo.svg" alt="Jompstart Logo" />

          {/* Web Navigation */}
          <div className="hidden md:flex lg:space-x-8 space-x-2 items-center">
            <Link to="/" className="text-BrandGray hover:text-BrandPrimary">
              Home
            </Link>
            {/* <Link
              to="/login"
              className="text-BrandGray hover:text-BrandPrimary text-sm lg:text-base"
            >
              Features
            </Link>
            <Link
              to="/login"
              className="text-BrandGray hover:text-BrandPrimary text-sm lg:text-base"
            >
              Blog
            </Link> */}
            <ScrollLink
              to="footer"
              spy={true}
              smooth={true}
              duration={200}
              className="text-BrandGray hover:text-BrandPrimary text-sm lg:text-base cursor-pointer"
              activeClass="some-active-class"
            >
              Contact Us
            </ScrollLink>
            <Dropdown menu={{ items: servicesMenu }} trigger={['click']}>
              <span className="text-BrandGray hover:text-BrandPrimary flex items-center gap-2">
                Services <ArrowDown2 className="ml-1" />
                {/* {servicesOpen ? (
                  <ArrowUp2 className="ml-1" />
                ) : (
                  <ArrowDown2 className="ml-1" />
                )} */}
              </span>
            </Dropdown>
            <Button
              onClick={handleLogin}
              className="bg-white text-BrandPrimary border border-BrandPrimary px-10 lg:px-16 py-[6px] lg:py-5 text-sm lg:text-base font-normal"
            >
              Login
            </Button>
            <Button
              onClick={handleGetStarted}
              className="bg-BrandPrimary text-white  px-4 lg:px-10 py-2 lg:py-5 text-sm lg:text-base font-normal"
            >
              Get Started
            </Button>
          </div>

          {/* Mobile Navigation */}
          <div className="md:hidden">
            <Dropdown
              menu={{ items: menuItems }}
              trigger={['click']}
              placement="bottomRight"
            >
              <HambergerMenu size="32" color="#EFA005" />
            </Dropdown>
          </div>
        </div>
      </SectionLayout>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div onClick={closeModal} className="flex justify-end">
          <CloseSquare size="20" color="#31005C" />
        </div>
        <h2 className="text-2xl font-bold">Get Started with Jompstart</h2>
        <p>Welcome to Jompstart! Click the button below to get started.</p>
      </Modal>
    </nav>
  );
};

export default Header;
