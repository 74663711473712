import {
  CallCalling,
  Dribbble,
  Facebook,
  Figma,
  Sms,
  Youtube,
} from 'iconsax-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionLayout from '../../layouts/SectionLayout';
import { scrollToTop } from '../../utils/Index';
import { Linkedin, Twitter } from 'lucide-react';
import {
  FaInstagram,
  FaInstagramSquare,
  FaTiktok,
  FaTwitter,
  FaTwitterSquare,
  FaYoutube,
} from 'react-icons/fa';

const Footer: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const text: string = `We make it easy to Find and Pay for Life’s Essential Service. We offer flexible payment plans for services like auto care, medical bills and home repairs. With transparent terms, quick approval, and a user-friendly platform, JompStart is promoting financial wellness and stability for those who need it the most.
  All financial services on JompStart, including Payment & Lending are powered by our licensed Payment & Banking Partners in respective countries
`;
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  // Define the shortened text length
  const shortTextLength = 30;
  const shortText = text.substring(0, shortTextLength) + '...';

  return (
    <nav className="bg-BrandSecondary py-4 md:border-t border-[#612e8d]">
      <SectionLayout>
        <div className=" mt-5">
          <div className="text-center md:px-0 lg:px-0 lg:flex md:text-left justify-between">
            <div className="logo md:w-full lg:w-2/6  mb-5 text-center md:text-left">
              <img
                src="./Assets/Jompstartwhitelogo.svg"
                alt="logo"
                className="pb-[20px]"
              />
              <div className="flex justify-center md:justify-start gap-2 pt-2">
                <a
                  href="https://www.facebook.com/jompstart"
                  target="blank_"
                  className=" cursor-pointer rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <Facebook size="20" color="#FFFFFF" />
                </a>
                <a
                  href="https://www.linkedin.com/company/jompstart"
                  target="blank_"
                  className="rounded-full cursor-pointer flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <Linkedin size="20" color="#FFFFFF" />
                </a>
                <a
                  href="https://www.x.com/jompstart"
                  target="blank_"
                  className="rounded-full cursor-pointer flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <FaTwitter size="20" color="#FFFFFF" />
                </a>
                <a
                  href="https://www.instagram.com/jompstart"
                  target="blank_"
                  className="rounded-full cursor-pointer flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <FaInstagram size="20" color="#FFFFFF" />
                </a>
                <a
                  href="https://www.tiktok.com/@jompstart"
                  target="blank_"
                  className=" cursor-pointer rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <FaTiktok size="20" color="#FFFFFF" />
                </a>
                <a
                  href="https://www.youtube.com/@jompstart"
                  target="blank_"
                  className=" cursor-pointer rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[35px] md:w-[35px] bg-[#462071]"
                >
                  <FaYoutube size="20" color="#FFFFFF" />
                </a>
              </div>
              <div className="text-container my-4 p-4  rounded shadow-sm">
                <p className="text-content text-white">
                  {isExpanded ? text : shortText}
                </p>
                <button
                  className="read-more-btn mt-2 text-BrandPrimary hover:underline"
                  onClick={toggleReadMore}
                >
                  {isExpanded ? 'Read Less' : 'Read More'}
                </button>
              </div>
            </div>

            <div className="text-[14px] md:text-[18px] flex flex-col md:flex-row justify-between xl:items-start  gap-6 md:gap-40">
              <div className="text-[14px] md:text-[18px] text-white text-base flex flex-col gap-3">
                <p className="font-semibold text-[18px]">Service Links</p>
                <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/school-fees" onClick={scrollToTop}>
                    School Fees
                  </Link>
                </span>
                <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/rent" onClick={scrollToTop}>
                    House Rent
                  </Link>
                </span>
                <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/transportation" onClick={scrollToTop}>
                    Transport Credit
                  </Link>
                </span>
              </div>

              <div className="text-white flex flex-col gap-[10px] md:gap-[20px]">
                <p className="font-semibold text-[18px]">Quick Links</p>
                <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/" onClick={scrollToTop}>
                    Home
                  </Link>
                </span>
                {/* <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/about" onClick={scrollToTop}>
                    Features
                  </Link>
                </span> */}
                <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/contact" onClick={scrollToTop}>
                    Contact us
                  </Link>
                </span>
                {/* <span className="hover:text-BrandPrimary cursor-pointer">
                  <Link to="/school-fees" onClick={scrollToTop}>
                    School Fees Service
                  </Link>
                </span> */}
              </div>

              {/* <div className="text-[14px] md:text-[18px] text-white text-base flex flex-col gap-3">
                <p className="font-semibold text-[18px]">Support</p>

                <span className="hover:text-BrandPrimary cursor-pointer">
                  Online Chat
                </span>
                <span className="hover:text-BrandPrimary cursor-pointer">
                  Whatsapp
                </span>
              </div> */}

              <div className="text-[14px] md:text-[18px] text-white flex flex-col gap-[10px] md:gap-[20px]">
                <p className="font-semibold text-[18px]">Contact Us</p>
                {/* <div className="flex gap-4 items-center ">
                  <span className="rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[45px] md:w-[45px] bg-[#462071]">
                    <CallCalling size="20" color="#Ffffff" />
                  </span>
                  <p className="flex flex-col">
                    <span className="text-xs text-BrandGray">
                      Have a question?
                    </span>
                    <span>
                      <a href="tel:310-437-2766">310-437-2766</a>
                    </span>
                  </p>
                </div> */}
                <div className="flex gap-4 lg:items-center justify-center">
                  <span className="rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[45px] md:w-[45px] bg-[#462071]">
                    <Sms size="20" color="#Ffffff" />
                  </span>
                  <div className="text-start flex flex-col">
                    <span className="text-xs text-BrandGray">
                      Contact us at
                    </span>
                    <span className="flex flex-col ">
                      <a href="mailto:hello@jompstart.com">
                        hello@jompstart.com
                      </a>
                      <a href="mailto:support@jompstart.com">
                        support@jompstart.com
                      </a>
                    </span>
                  </div>
                </div>
                {/* <div className="flex gap-4 items-center">
                  <span className="rounded-full flex justify-center items-center h-[40px] w-[40px] md:h-[45px] md:w-[45px] bg-[#462071]">
                    <Sms size="20" color="#Ffffff" />
                  </span>
                  <p className="text-start flex flex-col">
                    <span className="text-xs text-BrandGray">
                      Contact us at
                    </span>
                    <span>
                      <a href="mailto:support@jompstart.com">
                        support@jompstart.com
                      </a>
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>

          <p className="text-sm text-white xl:text-left mt-4 text-center leading-loose">
            JompStart is a Service Aggregation Platform (Marketplace) for both
            service providers and users of these services. We make it easy for
            our users to find and pay for Life’s Essential Services. We are a
            technology company and we are not a bank. All payments, banking and
            financial services on the platform are powered by our licensed
            partners in their respective countries. These include but not
            limited to Stripe Inc. in the US, Paystack Payments Limited (a
            Stripe Company in Africa) and Bankly Microfinance Bank Limited, a
            CBN-licensed Microfinance Bank, amongst others. Thank you for using
            and trusting our services, delivered with care in collaboration with
            our global partners
          </p>

          <div className="flex justify-between items-center mt-14">
            <p className="text-white text-center text-[14px] md:text[16px] ">
              Copyright &copy; {new Date().getFullYear()} All Rights Reserved
            </p>
            <div className="flex gap-4 items-center">
              <Link className="text-sm text-BrandGray" to="/terms&conditions">
                Terms & Condition
              </Link>
              {/* <Link className="text-sm text-BrandGray" to="/privacy">
                Privacy
              </Link> */}
            </div>
          </div>
        </div>
      </SectionLayout>
    </nav>
  );
};

export default Footer;
