import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import { RoutePaths } from '../route-paths';
import { PrivateUserRoute, PrivateVendorRoute } from '../utils';
import UserSavingsDetails from 'pages/user/dashboard/savings/HowJomptVaultWorks';
const DashboardLayout = lazy(() => import('../../layouts/dashboard-layout'));
// const ConfirmDashboardPage = lazy(
//   () => import('../../pages/user/dashboard/paystackConfirm')
// );
const UserBookingsPage = lazy(
  () => import('../../pages/user/dashboard/bookings')
);
const UserCustomerSupportPage = lazy(
  () => import('../../pages/user/dashboard/customer-support')
);
const UserServicesPage = lazy(
  () => import('../../pages/user/dashboard/services')
);
const CustomerServicesPage = lazy(
  () => import('../../pages/user/dashboard/services/CustomerServices')
);
const CustomerServiceDetailsPage = lazy(
  () =>
    import('../../pages/user/dashboard/ServiceDetails/CustomerServiceDetails')
);

const LoanDetailsPage = lazy(
  () => import('../../pages/user/dashboard/loans/loanDetails')
);
const UserServicesPageDetails = lazy(
  () => import('../../pages/user/dashboard/ServiceDetails')
);
const MakePaymentPage = lazy(
  () => import('../../pages/user/dashboard/MakePayment')
);
const UserSettingsPage = lazy(
  () => import('../../pages/user/dashboard/settings')
);
const TransactionsPage = lazy(
  () => import('../../pages/user/dashboard/transanctions')
);

const SavingsPage = lazy(
  () => import('../../pages/user/dashboard/savings/JompVault')
);
const JompVaultPage = lazy(
  () => import('../../pages/user/dashboard/savings/HowJomptVaultWorks')
);
const JompVaultDetailsPage = lazy(
  () => import('../../pages/user/dashboard/savings/HouseRent')
);

const LoansPage = lazy(() => import('../../pages/user/dashboard/loans'));

const UserDashboardPage = lazy(() => import('../../pages/user/dashboard'));

const ProfilePage = lazy(
  () => import('../../pages/user/dashboard/profile/Profile')
);
const PasswordResetPage = lazy(
  () => import('../../pages/user/dashboard/password-reset/PasswordReset')
);

const SchoolfeePage = lazy(
  () => import('../../pages/user/dashboard/school-fees/SchoolFee')
);

const TransportPage = lazy(
  () => import('../../pages/user/dashboard/transport')
);

const InstutionSchoolfeePage = lazy(
  () => import('../../pages/user/dashboard/institution-fees/InstitutionFee')
);

const UserCookiesPage = lazy(
  () => import('../../pages/user/dashboard/legal/Cookies')
);
const UserPrivacyPage = lazy(
  () => import('../../pages/user/dashboard/legal/Privacy')
);
const UserTermsPage = lazy(
  () => import('../../pages/user/dashboard/legal/Terms')
);

const PublicPaymentPage = lazy(
  () => import('../../pages/user/dashboard/public-payment/')
);

const OneTimePaymentPage = lazy(
  () => import('../../pages/user/dashboard/one-time-payment/')
);
const BeginSavingsTarget = lazy(
  () => import('../../pages/user/dashboard/savings/BeginSavingsTarget')
);

const WalletPage = lazy(() => import('../../pages/user/dashboard/wallet'));
const LoanRepayment = lazy(
  () => import('../../pages/user/dashboard/loan-repayment')
);
const LoanRepaymentDetails = lazy(
  () => import('../../pages/user/dashboard/loan-repayment-details')
);

const WithdrawalPage = lazy(
  () => import('../../pages/user/dashboard/bank-withdrawal/Withdrawal')
);
const userDashboardRoutes: RouteObject[] = [
  {
    path: RoutePaths.PUBLIC_PAYMENT,
    element: <PublicPaymentPage />,
  },
  {
    path: RoutePaths.USER_PUBLIC_ONE_TIME_PAYMENT,
    element: <OneTimePaymentPage />,
  },
  // {
  //   path: RoutePaths.USER_PAYSTACK_CONFIRM,
  //   element: (
  //     <PrivateUserRoute>
  //       <ConfirmDashboardPage />
  //     </PrivateUserRoute>
  //   ),
  // },
  {
    path: RoutePaths.USER_DASHBOARD,
    element: (
      <PrivateUserRoute>
        <DashboardLayout />
      </PrivateUserRoute>
    ),
    children: [
      {
        path: RoutePaths.USER_DASHBOARD,
        element: (
          <PrivateUserRoute>
            <UserDashboardPage />
          </PrivateUserRoute>
        ),
        index: true,
      },
      // {
      //   path: RoutePaths.USER_PAYSTACK_CONFIRM,
      //   element: (
      //     <PrivateUserRoute>
      //       <ConfirmDashboardPage />
      //     </PrivateUserRoute>
      //   ),
      // },

      {
        path: RoutePaths.USER_SERVICES,
        element: (
          <PrivateUserRoute>
            <UserServicesPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.CUSTOMER_SERVICES,
        element: (
          <PrivateUserRoute>
            <CustomerServicesPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.CUSTOMER_SERVICES_DETAILS,
        element: (
          <PrivateUserRoute>
            <CustomerServiceDetailsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_VIEW_LOAN_REPAYMENT,
        element: (
          // <PrivateUserRoute>
          <LoanDetailsPage />
          // </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SERVICE_DETAILS,
        element: (
          <PrivateUserRoute>
            <UserServicesPageDetails />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SERVICE_PAYMENT,
        element: (
          <PrivateUserRoute>
            <MakePaymentPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_WALLET,
        element: (
          <PrivateUserRoute>
            <WalletPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_BANK_WITHDRAWAL,
        element: (
          <PrivateUserRoute>
            <WithdrawalPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_LOAN_REPAYMENTS,
        element: (
          <PrivateUserRoute>
            <LoanRepayment />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_LOAN_REPAYMENT_DETAILS,
        element: (
          <PrivateUserRoute>
            <LoanRepaymentDetails />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SCHOOL_FEES,
        element: (
          <PrivateUserRoute>
            <SchoolfeePage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_INSTUTION_SCHOOL_FEES,
        element: (
          <PrivateUserRoute>
            <InstutionSchoolfeePage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_TRANSPORTATION,
        element: (
          <PrivateUserRoute>
            <TransportPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SETTINGS,
        element: (
          <PrivateUserRoute>
            <ProfilePage />
          </PrivateUserRoute>
        ),
      },
      // {
      //   path: RoutePaths.USER_PROFILE,
      //   element: (
      //     <PrivateUserRoute>
      //       <ProfilePage />
      //     </PrivateUserRoute>
      //   ),
      // },
      {
        path: RoutePaths.USER_PASSWORD_RESET,
        element: (
          <PrivateUserRoute>
            <PasswordResetPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_PRIVACY,
        element: (
          <PrivateUserRoute>
            <UserSettingsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_TRANSACTIONS,
        element: (
          <PrivateUserRoute>
            <TransactionsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SUPPORT,
        element: (
          <PrivateUserRoute>
            <UserCustomerSupportPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_BOOKINGS,
        element: (
          <PrivateUserRoute>
            <UserBookingsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_PRIVACY_POLICY,
        element: (
          <PrivateUserRoute>
            <UserPrivacyPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_COOKIES_POLICY,
        element: (
          <PrivateUserRoute>
            <UserCookiesPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_TERMS,
        element: (
          <PrivateUserRoute>
            <UserTermsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_LOAN_REPAYMENT,
        element: (
          <PrivateUserRoute>
            <LoansPage />
          </PrivateUserRoute>
        ),
      },

      {
        path: RoutePaths.USER_SAVINGS,
        element: (
          <PrivateUserRoute>
            <SavingsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SAVINGS_JOMPVAULT,
        element: (
          <PrivateUserRoute>
            <JompVaultPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SAVINGS_JOMPVAULT_DETAILS,
        element: (
          <PrivateUserRoute>
            <JompVaultDetailsPage />
          </PrivateUserRoute>
        ),
      },
      {
        path: RoutePaths.USER_SAVINGS_DETAILS,
        element: (
          <PrivateUserRoute>
            <UserSavingsDetails />
          </PrivateUserRoute>
        ),
      },
    ],
  },
  // {
  //   path: RoutePaths.USER_PAYSTACK_CONFIRM,
  //   element: (
  //     <PrivateUserRoute>
  //       <ConfirmDashboardPage />
  //     </PrivateUserRoute>
  //   ),
  // },
];

export default userDashboardRoutes;
